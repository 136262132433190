.course_section{
    background-color: rgb(186, 39, 39);
    padding:20px;
    color:rgb(255, 255, 255);
    border-radius:10px;
    margin: auto;
}
.course_section1{
    background-color: rgb(132, 96, 96);
    padding:20px;
    color:rgb(255, 255, 255);
    border-radius:10px;
}
.contact_form input,textarea{
    width:100%;
    margin-bottom: 10px;
    padding: 6px;
    border: 1px solid rgb(19,19,19);
}
.submit_btn{
    background-color: #0c7bc0;
    border: none;
    color: #fff;
    font-weight: 500;
    margin-bottom: 5px;
    padding: 10px;
    text-transform: uppercase;
    width: 100px;
}
.submit_btnOne{
    background-color: #36ca58;
    border: none;
    color: #fff;
    font-weight: 500;
    margin-bottom: 5px;
    padding: 10px;
    text-transform: uppercase;
    width: 100%;
    border-radius: 20px;
}
.submit_btn_div{
    display: flex;
    justify-content: center;
}
.submit_btnOne:hover{
    background-color: #156f2a;
    width: 80%;
    justify-content: center;
}


.about_heading{
    color: #0c7bc0;
}
.login_input{
    border:1px solid #ffffff;
    border-radius: 20px;
    padding:8px;
    margin-bottom: 8px;
}
.login_input input{
    width:90%;
    border:none;
    background-color: transparent;
    outline: none;
    color: white;
}

.login_right_side{
    background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
    border-radius: 15px;
    height: fit-content;
    padding: 20px 10px;
}

.login_right_side h3{
    display: flex;
    color: white;
    justify-content: center;
}
.login_links{
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;    
}
.login_links a{
     color: white;
     text-decoration: none;
}
